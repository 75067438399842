import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';

// import your plugins here.

import "footer/js/footer.js";
import "header/js/header.js";
import "image/js/image.js";
import "stage/js/stage.js";
import "youtube_video/js/youtube_video.js";

import "../../events/js/database_list_filters.js"


plugin_registry.init();
