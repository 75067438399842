import Swiper from "swiper/bundle";
import 'swiper/css/bundle';

import "../scss/stage.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";

@plugin_registry.register("Stage")
class Stage extends PluginBase {
    constructor($node) {
        super($node);
        this._$node = $node;
    }

    loaded($node) {
        super.loaded($node);
        const swiperEl = this._$node.querySelector('[data-js-select="swiper"]');
        const slidesCount = swiperEl.querySelector('.swiper-wrapper')
            ? swiperEl.querySelector('.swiper-wrapper').children.length
            : 0;
        this._swiper = new Swiper(
            swiperEl,
            {
                spaceBetween: 10,
                allowSlidePrev: true,
                allowSlideNext: true,
                centeredSlides: true,
                loop: true,
                autoHeight: true,
                breakpoints: {
                    640: {
                        slidesPerView: slidesCount < 4 ? 1 : 1.5,
                        loop: true
                    }
                },
                navigation: {
                    nextEl: this._$node.querySelector('[data-js-select="swiper-next"]'),
                    prevEl: this._$node.querySelector('[data-js-select="swiper-prev"]'),
                },
                pagination: {
                el: this._$node.querySelector('[data-js-select="swiper-pagination"]'),
                },
            },
        );
    }
}
